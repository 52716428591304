/* eslint func-names:0 */

export default {
  props: {
    formData: {
      type: Object,
      required: false,
    },
  },
  methods: {
    getByObjectPath(obj, path) {
      if (!path) {
        return null;
      }
      return path.split('.').reduce((current, key) => (current && current[key] ? current[key] : null), obj);
    },

    getCountByObjectPath(path) {
      const value = this.formData ? this.getByObjectPath(this.formData, path) : null;
      if (value) {
        return value.length;
      }
      return 0;
    },
  },
};
