<template>
  <neris-form
      v-if="structure"
      v-model="formData"
      :structure="structure"
      :pre-defined-categories="preDefinedCategories"
      :error-messages="errorsMessages"
      :empty-objects-to-null="true"
  >
    <template v-slot:actions>
      <v-btn
          :disabled="true"
          class="mr-3"
      >
        Cancel
      </v-btn>
      <v-btn
          :loading="false"
          color="primary"
          @click="validateAndSave"
      >
        Validate
      </v-btn>
    </template>
  </neris-form>
</template>
<script>
import apiv4 from '@/api/apiv4';
import preDefinedCategories from '@/components/Neris/categories';
import NerisForm from '@/components/Neris/NerisForm';
import validation from '@/components/Neris/Services/validation';

/* eslint-disable no-param-reassign */
export default {
  components: {
    NerisForm,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      structure: null,
      formData: null,
      preDefinedCategories: null,
      errorsMessages: {},
    };
  },

  mounted() {
    this.formData = null;
    this.preDefinedCategories = preDefinedCategories[this.type];
    this.fetchStructure();
  },
  methods: {
    async fetchStructure() {
      this.structure = await apiv4.get(`/neris/${this.type}`)
        .then(response => response.data);
    },
    validateAndSave() {
      this.errorsMessages = validation.validateFormData(this.formData, this.structure);
      console.log(this.errorsMessages, 'errorMessages');
    },
  },
};
</script>
