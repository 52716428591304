<template>
  <v-card class="neris-form">
    <v-row>
      <v-col
          md="3"
          class="pt-0 pb-0 pr-0"
      >
        <div class="neris-form-v-list-wrapper">
          <navigation
              v-if="categories && currentCategory"
              :categories="categories"
              :current-category="currentCategory"
              :form-data="formData"
              :error-messages="errorMessages"
              color="#f5f5f6"
              @chooseCategory="chooseCategory"
          />
        </div>
      </v-col>

      <v-col
          md="9"
          class="pt-0 pb-0 pl-0"
      >
        <div class="form-content-container">
          <v-card
              :loading="loading"
              flat
          >
            <v-card-title v-if="currentCategory">
              <span class="mr-4">
                {{ currentCategory.title }}
                {{ currentCategory.fieldProperties.required ? ' (required)' : '' }}
              </span>
              <v-spacer />
              <v-btn
                  v-if="multiple"
                  class="mr-3"
                  @click="addItem"
              >
                +Add
              </v-btn>
              <slot name="actions" />
            </v-card-title>
            <v-divider />
            <v-card
                v-if="structure && currentCategory"
                :key="'category_' + currentCategory.id"
                class="form-content-container-text"
                flat
            >
              <v-card-text>
                <custom-form
                    v-if="currentCategory.fieldProperties.customImplementation"
                    ref="currentForm"
                    :key="'form_' + currentCategory.id"
                    :value="getByObjectPath(formData, currentCategory.field)"
                    :structure="currentCategory.structure"
                    :field-properties="currentCategory.fieldProperties"
                    :error-messages.sync="errorMessages"
                    :empty-objects-to-null="true"
                    @input="(value) => setValueByObjectPath(currentCategory.field, value)"
                    @update:errorMessages="(value) =>
                      setErrorMessages(currentCategory.field, value)"
                />
                <simple-form
                    v-else-if="currentCategory.field"
                    ref="currentForm"
                    :key="'form_' + currentCategory.id"
                    :value="getByObjectPath(formData, currentCategory.field)"
                    :structure="currentCategory.structure"
                    :field-properties="currentCategory.fieldProperties"
                    :multiple="multiple"
                    :empty-objects-to-null="!multiple"
                    :nesting-number="0"
                    :is-dialog="isDialog"
                    :dialog-type="dialogType"
                    :dialog-title="dialogTitle"
                    :error-messages="getByObjectPath(errorMessages, currentCategory.field) || {}"
                    @input="(value) => setValueByObjectPath(currentCategory.field, value)"
                    @update:errorMessages="(value) =>
                      setErrorMessages(currentCategory.field, value)"
                />
                <simple-form
                    v-else
                    ref="currentForm"
                    :key="'form_' + currentCategory.index"
                    v-model="formData"
                    :structure="currentCategory.structure"
                    :field-properties="currentCategory.fieldProperties"
                    :empty-objects-to-null="true"
                    :nesting-number="0"
                    :is-dialog="isDialog"
                    :dialog-type="dialogType"
                    :dialog-title="dialogTitle"
                    :error-messages.sync="errorMessages"
                />
              </v-card-text>
            </v-card>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
/* eslint-disable no-param-reassign */
import _ from 'lodash';
import SimpleForm from '@/components/Neris/SimpleForm';
import Navigation from '@/components/Neris/Navigation/Navigation';
import formsMixin from '@/components/Neris/formsMixin';
import CustomForm from '@/components/Neris/CustomForm';

export default {
  name: 'NerisForm',
  components: { CustomForm, Navigation, SimpleForm },
  mixins: [formsMixin],
  props: {
    structure: {
      type: Object,
      required: true,
    },
    preDefinedCategories: {
      type: Object,
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    errorMessages: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      edited: false,
      saving: false,
      loading: false,
      categoriesStructure: null,
      currentCategoryItem: null,
    };
  },
  mounted() {
    this.categoriesStructure = this.preDefinedCategories || this.getNoPreDefinedCategories();
    this.formData = this.value ? _.cloneDeep(this.value) : null;
  },
  computed: {
    currentCategory() {
      return this.currentCategoryItem || this.categories.main;
    },
    multiple() {
      return this.currentCategory && this.currentCategory.fieldProperties.isArray;
    },
    isDialog() {
      return this.currentCategory && !!this.currentCategory.fieldProperties.isDialog;
    },
    dialogType() {
      return this.isDialog && this.currentCategory.fieldProperties.class
        ? this.currentCategory.fieldProperties.class
        : null;
    },
    dialogTitle() {
      return this.isDialog
        ? this.currentCategory.fieldProperties.dialogTitle || this.currentCategory.title
        : null;
    },
    categories() {
      return this.structure && this.categoriesStructure
        ? this.getCategories()
        : {};
    },
  },
  methods: {
    getNoPreDefinedCategories() {
      return {
        main: {
          title: this.title || 'Main',
          type: 'category',
          icon: null,
          field: null,
          subcategories: null,
          expandable: true,
          structure: {},
          fieldProperties: {
            isArray: false,
          },
        },
      };
    },

    getCategories() {
      const structure = _.cloneDeep(this.structure);
      let categories = this.fillPreDefinedCategories(
        structure,
        _.cloneDeep(this.categoriesStructure),
      );
      categories = this.getRemainingCategories(
        structure,
        categories,
        categories.main,
        1,
      );
      return categories;
    },

    getRemainingCategories(structure, categories, mainCategory, level) {
      _.forEach(structure, (structureItem, field) => {
        const categorize = this.isCategorizable(structureItem);
        const fieldProperties = _.cloneDeep(structureItem);
        delete fieldProperties.structure;
        delete fieldProperties.values;
        delete fieldProperties.allowableValues;

        if (categorize && level <= 2) {
          const category = this.makeCategory(
            field,
            structureItem.title ? structureItem.title : field,
            `${mainCategory.field ? `${mainCategory.field}.` : ''}${field}`,
            null,
            true,
            {},
            fieldProperties,
          );
          if (structureItem.isArray) {
            category.structure = structureItem.structure;
          } else {
            category.subcategories = this.getRemainingCategories(
              structureItem.structure,
              {},
              category,
              level + 1,
            );
          }

          categories[field] = category;
        } else {
          mainCategory.structure[field] = structureItem;
        }
      });
      return categories;
    },

    fillPreDefinedCategories(structure, categories) {
      _.forEach(categories, (category, name) => {
        category.id = name;
        if (category.field) {
          const categoryStructure = structure[category.field];

          const fieldProperties = this.makeFieldProperties(structure[category.field]);

          delete structure[category.field];
          if (!category.title) {
            category.title = categoryStructure.title ? categoryStructure.title : category.field;
          }
          category.fieldProperties = { ...category.fieldProperties, ...fieldProperties };
          if (category.expandable) {
            category.subcategories = {};
            _.forEach(categoryStructure.structure, (nestedCategory, field) => {
              const fieldPropertiesNested = this.makeFieldProperties(nestedCategory);
              if (nestedCategory.type === 'object') {
                category.subcategories[field] = this.makeCategory(
                  `${category.field}_${field}`,
                  nestedCategory.title ? nestedCategory.title : field,
                  `${category.field}.${field}`,
                  null,
                  false,
                  nestedCategory.structure,
                  fieldPropertiesNested,
                );
              } else {
                category.structure[field] = nestedCategory;
              }
            });
          } else {
            category.structure = categoryStructure.structure;
          }
        } else if (category.subcategories) {
          category.subcategories = this.fillPreDefinedCategories(structure, category.subcategories);
        }
      });
      return categories;
    },

    makeFieldProperties(structure) {
      const fieldProperties = _.cloneDeep(structure);
      delete fieldProperties.structure;
      delete fieldProperties.values;
      delete fieldProperties.allowableValues;
      return fieldProperties;
    },

    makeCategory(id, title, field, subcategories, expandable, structure, fieldProperties) {
      return {
        type: 'category',
        id,
        title,
        field,
        subcategories,
        expandable,
        structure,
        fieldProperties,
      };
    },

    getByObjectPath(obj, path) {
      if (!path) {
        return null;
      }
      return path.split('.').reduce((current, key) => (current ? current[key] : null), obj);
    },

    setValueByObjectPath(path, value) {
      if (!path || typeof path !== 'string') {
        throw new Error('Path must be a non-empty string');
      }
      const keys = path.split('.');
      const lastKey = keys.pop();

      const formData = this.formData ? _.cloneDeep(this.formData) : {};
      let current = formData;

      keys.forEach(key => {
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      });

      current[lastKey] = value;
      this.formData = formData;
    },

    setErrorMessages(path, value) {
      if (!path || typeof path !== 'string') {
        throw new Error('Path must be a non-empty string');
      }
      const keys = path.split('.');
      const lastKey = keys.pop();

      const errorMessages = _.cloneDeep(this.errorMessages);
      let current = errorMessages;

      keys.forEach(key => {
        if (!current[key]) {
          current[key] = {};
        }
        current = current[key];
      });

      current[lastKey] = value;
      this.$emit('update:errorMessages', errorMessages);
    },

    chooseCategory(category) {
      this.currentCategoryItem = category;
    },

    addItem() {
      if (this.$refs.currentForm) {
        this.$refs.currentForm.addItem();
      }
    },
    isCategorizable(structureItem) {
      let categorize = structureItem.type === 'object';
      if (structureItem.type === 'object'
          && Object.prototype.hasOwnProperty.call(structureItem, 'categorize')
      ) {
        categorize = structureItem.categorize;
      }
      return categorize;
    },
  },
};
</script>
<style>
.neris-form {
  .neris-form-v-list-wrapper {
    background-color: #f5f5f6;
    padding-left: 10px;
    border-right: 1px solid #e1e1e3;
    height: calc(100vh - 90px);
    overflow-y: scroll;

    .active-item.v-list-item, .active-item .v-list-group__header,
    .v-list-item--active .v-list-group__header {
      background-color: #e0e0e0;
      color: #e04237;
    }
    .v-list-group--active {
      background-color: #eaeaea;
    }
    .no-prepand-icon {
      .v-list-group__header__prepend-icon {
        display: none;
      }
    }
    .v-list-item__icon {
      margin-right: 15px;
    }
  }

  .form-content-container {
    height: calc(100vh - 90px);
    .form-content-container-text {
      height: calc(100vh - 160px);
      overflow: auto;
    }
  }
}
</style>
