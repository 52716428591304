<template>
  <div>
    <template v-if="multiple">
        <span v-if="!formData || !formData.length">
          No added items
        </span>
      <v-container v-if="formData && formData.length">
        <v-row
            v-for="(item, index) in formData"
            v-bind:key="'nested_object_form_row' + index + '_' + keyForceReload"
            class="align-start"
        >
          <div
            class="mt-4"
            style="width: 32px"
          >
            <v-icon
                v-if="isDialog
                  && errorMessagesModel
                  && hasErrorRecursive(errorMessagesModel[index])"
                class="inline_span"
                color="red"
                dense
            >
              mdi-alert-circle
            </v-icon>
          </div>
          <div class="mr-3 mt-4"><b>{{ index + 1 }}.</b></div>

          <dialog-form
            v-if="isDialog"
            v-model="formData[index]"
            class="flex-grow-1"
            :title="dialogTitle"
            :dialog-view-type="dialogType"
            :structure="structure"
            :error-messages="errorMessagesModel[index] || {}"
          />
          <wrapped-form
              v-else
              v-model="formData[index]"
              :structure="structure"
              :field-properties="fieldProperties"
              :multiple="false"
              :nesting-number="nestingNumber + 1"
              :show-toolbar="false"
              :error-messages="errorMessagesModel[index] || {}"
              class="flex-grow-1"
          />
          <v-btn
              color="red"
              text
              class="ml-2 mt-2"
              icon
              @click="deleteItem(index)"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </template>
    <template v-else>
      <template v-if="formData">
        <div
            v-for="(item, fieldName) in structure"
            v-bind:key="'field_' + fieldName"
        >
          <dynamic-field
              v-model="formData[fieldName]"
              :field-properties="item"
              :field-name="fieldName"
              :nesting-number="nestingNumber"
              :error-messages="errorMessagesModel[fieldName] || []"
          />
        </div>
      </template>
    </template>
  </div>
</template>
<script>

import _ from 'lodash';
import DynamicField from '@/components/Neris/DynamicField';
import formsMixin from './formsMixin';

export default {
  name: 'SimpleForm',
  mixins: [formsMixin],

  components: {
    DynamicField,
    'wrapped-form': () => import('@/components/Neris/WrappedForm'),
    'dialog-form': () => import('@/components/Neris/DialogForm'),
  },

  props: {
    structure: {
      type: Object,
      required: true,
    },
    fieldProperties: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    nestingNumber: {
      type: Number,
      default: 0,
    },
    isDialog: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: null,
    },
    dialogTitle: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      errorMessagesModel: [],
      keyForceReload: 0,
    };
  },
  watch: {
    errorMessages: {
      handler(value) {
        this.errorMessagesModel = value;
      },
      deep: true,
    },
  },
  mounted() {
    const blankData = this.multiple ? [] : {};
    this.formData = this.value ? _.cloneDeep(this.value) : blankData;
    this.initializing = false;
    this.errorMessagesModel = this.errorMessages;
  },
  methods: {
    hasErrorRecursive(errorMessages) {
      if (!errorMessages) {
        return false;
      }
      let hasErrors = false;
      _.forEach(errorMessages, (item) => {
        if (typeof item === 'string') {
          hasErrors = true;
        } else {
          const hasErrorNested = this.hasErrorRecursive(item);
          if (hasErrorNested) {
            hasErrors = true;
          }
        }
      });
      return hasErrors;
    },

    addItem() {
      let formData = _.cloneDeep(this.formData);
      if (!formData) {
        formData = [];
      }
      if (formData.length === 0) {
        this.errorMessagesModel = [];
        this.$emit('update:errorMessages', []);
      }
      formData.push(null);
      this.formData = formData;
    },
    deleteItem(index) {
      const formData = _.cloneDeep(this.formData);
      formData.splice(index, 1);
      this.formData = formData;

      if (index in this.errorMessagesModel) {
        const errorMessagesModel = _.cloneDeep(this.errorMessagesModel);
        errorMessagesModel.splice(index, 1);
        this.errorMessagesModel = errorMessagesModel;
        this.$emit('update:errorMessages', errorMessagesModel);
      }
      this.keyForceReload++;
    },
  },
};
</script>

<style lang="scss" module>

</style>
