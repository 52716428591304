import profileSettings from '@/data/TableHeaders/profileSettingsHeaders';

export default {
  notifications: {
    modificationHeader: 'Report Modification Notification (on site)',
    reportsOnDeviceHeader: 'Reports on Device Notifications',
    unfinishedReportsHeader: 'Unfinished Transmitted Reports Notifications',
    tableHeader: profileSettings.notifications,
    deletedUniqueFormsHeader: 'Deleted Unique Forms Notifications',
    adminCeNotificationsHeader: 'CE Notifiable Admins',
  },
  billingExport: {
    header: 'Export Billing Data',
    emailNotificationAfterUploadTitle: 'Email notification after successful generation/upload',
    ftpSettingsTitle: 'Current FTP Settings',
    exportSettingsTitle: 'Current Export Settings',
    server: 'Server:',
    user: 'User:',
    type: 'Type:',
    currentPassword: 'Current Password:',
    uploadDirectory: 'Upload Directory:',
    updatedIncidents: 'Export updated incidents',
    noTransport: 'Include patients NOT (\'N\') transport in export',
    withoutAddress: 'Export patients WITHOUT address',
    unknownPatient: 'Export patients as Jane/John Doe',
    emptyPatientName: 'Export Incidents where LastName is EMPTY',
    useCompression: 'Use Compression',
    excludeProviders: 'Exclude Providers',
    format: 'Export Format',
    name: 'Name',
    active: 'Active',
  },
  billingExportSecondary: {
    header: 'Export Billing Data - Secondary',
  },
  unfilteredExport: {
    header: 'Unfiltered Export Data',
    ftpSettingsTitle: 'Current FTP Settings',
    server: 'Server:',
    user: 'User:',
    type: 'Type:',
    currentPassword: 'Current Password:',
    uploadDirectory: 'Upload Directory:',
    exportSettingsTitle: 'Current Export Settings',
    updatedIncidents: 'Export updated incidents',
    useCompression: 'Use Compression',
    format: 'Export Format',
  },
  mindBaseExport: {
    exportDestinationTitle: 'SFTP Server settings',
    header: 'MindBase Export Data',
    ftpSettingsTitle: 'Current SFTP Settings',
    server: 'SFTP Server:',
    user: 'User:',
    currentPassword: 'Current Password:',
    uploadDirectory: 'Upload Directory:',
    exportSettingsTitle: 'Current Export Settings',
  },
  dhsExport: {
    header: 'Export DHS Data',
    exportDestinationTitle: 'Export Destination Settings',
    exportSettingsTitle: 'Current Export Settings',
    type: 'Type:',
    currentPassword: 'Current Password:',
    uploadDirectory: 'Upload Directory:',
    updatedIncidents: 'Export updated incidents',
    url: 'URL:',
    username: 'Username:',
    useCompression: 'Use Compression',
    mergeXmlFiles: 'Merge Xml Files',
  },
  security: {
    header: 'Security Settings',
    apiAccessTitle: 'API Access',
    securityIPTitle: 'Security (Access Restriction by IP)',
    apiKey: 'Current API Key',
    name: 'Name',
    ipAddress: 'Net',
  },
  timeForExport: 'Time the Export will happen:',
  newEmailBtn: 'New email',
  editBtn: 'Edit',
  copyBtn: 'Copy',
  deleteEmailTitle: 'Delete email',
  deleteNotificationTitle: 'Delete notification',
  deleteAccessRestrictionTitle: 'Delete access restriction',
  regenerateBtn: 'Re-generate',
  timePickerLabel: 'Time to Notify (by PDT)',
  changePasswordTitle: 'Change password',
  changePasswordText: 'Do you really want to change password?',
};
