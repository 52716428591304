const FIELD_TYPES = {
  STRING: 'string',
  TEXTAREA: 'textarea',
  SELECT: 'select',
  NESTED_SELECT: 'nested-select',
  INTEGER: 'integer',
  FLOAT: 'float',
  BOOLEAN: 'boolean',
  DATETIME: 'datetime',
  OBJECT: 'object',
  CUSTOM: 'custom',
};

export default FIELD_TYPES;
