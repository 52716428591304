<template>
  <div>
    <template
      v-if="fieldProperties.isArray
      && (
          fieldType !== fieldTypes.SELECT
          && fieldType !== fieldTypes.NESTED_SELECT
          && fieldType !== fieldTypes.OBJECT
          && !fieldProperties.customImplementation
      )"
    >
      {{ fieldName }} - {{ fieldProperties.type }} - IS NOT ARRAYABLE
    </template>
    <template v-else>
      <v-text-field
          v-if="fieldType === fieldTypes.STRING"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          :maxlength="fieldProperties.maxLength || undefined"
          :counter="!!fieldProperties.maxLength"
          outlined
      />
      <v-textarea
          v-else-if="fieldType === fieldTypes.TEXTAREA"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          :maxlength="fieldProperties.maxLength || undefined"
          :counter="!!fieldProperties.maxLength"
          outlined
      />
      <select-field
          v-else-if="fieldType === fieldTypes.SELECT"
          v-model="innerValue"
          :label="label"
          :items="selectValues"
          :multiple="fieldProperties.isArray"
          :chips="fieldProperties.isArray"
          :deletable-chips="fieldProperties.isArray"
          :error-messages="errorMessages"
          outlined
          clearable
      />
      <nested-select-field
          v-else-if="fieldType === fieldTypes.NESTED_SELECT"
          v-model="innerValue"
          :label="label"
          :items="selectValues"
          :multiple="fieldProperties.isArray"
          :error-messages="errorMessages"
          outlined
          clearable
      />
      <integer-field
          v-else-if="fieldType === fieldTypes.INTEGER"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          outlined
      />
      <v-text-field
          v-else-if="fieldType === fieldTypes.FLOAT"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          type="number"
          outlined
      />
      <boolean-field
          v-else-if="fieldType === fieldTypes.BOOLEAN"
          v-model="innerValue"
          :field-properties="fieldProperties"
          :label="label"
          :error-messages="errorMessages"
      />
      <date-time-field
          v-else-if="fieldType === fieldTypes.DATETIME"
          v-model="innerValue"
          :label="label"
          :error-messages="errorMessages"
          outlined
      />
      <wrapped-form
          v-else-if="fieldType === fieldTypes.OBJECT
            && fieldProperties.structure
            && wrappable"
          v-model="innerValue"
          :structure="fieldProperties.structure"
          :field-properties="fieldProperties"
          :label="label"
          :show-toolbar="true"
          :multiple="fieldProperties.isArray"
          :nesting-number="nestingNumber + 1"
          :error-messages="errorMessages"
          :empty-objects-to-null="true"
          outlined
      />
      <simple-form
          v-else-if="fieldType === fieldTypes.OBJECT
          && fieldProperties.structure
          && !wrappable"
          v-model="innerValue"
          :structure="fieldProperties.structure"
          :field-properties="fieldProperties"
          :multiple="fieldProperties.isArray"
          :error-messages="errorMessages"
          :empty-objects-to-null="true"
      />
      <template v-else-if="fieldType === fieldTypes.OBJECT && !fieldProperties.structure" />
      <custom-form
          v-else-if="fieldType === fieldTypes.CUSTOM"
          v-model="innerValue"
          :structure="fieldProperties.structure"
          :field-properties="fieldProperties"
          :label="label"
          :error-messages="errorMessages"
      />
      <div
        v-else
        class="mb-2 mt-2"
      >
        {{ fieldName }} - {{ fieldProperties.type }} - NOT IMPLEMENTED YET
      </div>
    </template>
  </div>
</template>
<script>
import BooleanField from '@/components/Neris/Controls/BooleanField';
import IntegerField from '@/components/Neris/Controls/IntegerField';
import DateTimeField from '@/components/Neris/Controls/DateTimeField';
import NestedSelectField from '@/components/Neris/Controls/NestedSelectField';
import WrappedForm from '@/components/Neris/WrappedForm';
import FIELD_TYPES from '@/components/Neris/fieldTypes';
import SelectField from '@/components/Neris/Controls/SelectField';
import CustomForm from '@/components/Neris/CustomForm';

export default {
  name: 'DynamicField',
  components: {
    SelectField,
    DateTimeField,
    WrappedForm,
    IntegerField,
    BooleanField,
    NestedSelectField,
    CustomForm,
    'simple-form': () => import('@/components/Neris/SimpleForm'),
  },
  data() {
    return {
      fieldTypes: FIELD_TYPES,
    };
  },
  props: {
    value: {
      required: false,
    },
    fieldName: {
      type: String,
      required: true,
    },
    fieldProperties: {
      type: Object,
      required: true,
    },
    nestingNumber: {
      type: Number,
      default: 0,
    },
    errorMessages: {
      type: [Object, Array],
      required: true,
    },
  },
  emits: ['input'],
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', this.prepareValue(value));
      },
    },
    wrappable() {
      return this.isWrappable(this.fieldProperties);
    },
    fieldType() {
      let fieldType = null;
      if (this.fieldProperties.type === 'string'
          && !this.fieldProperties.allowableValues
          && (!this.fieldProperties.maxLength || this.fieldProperties.maxLength < 500)
      ) {
        fieldType = this.fieldTypes.STRING;
      } else if (this.fieldProperties.type === 'string'
          && !this.fieldProperties.allowableValues
          && (this.fieldProperties.maxLength && this.fieldProperties.maxLength >= 500)
      ) {
        fieldType = this.fieldTypes.TEXTAREA;
      } else if (this.fieldProperties.type === 'string' && this.fieldProperties.allowableValues) {
        fieldType = this.fieldTypes.SELECT;
      } else if (this.fieldProperties.type === 'enum') {
        fieldType = this.fieldTypes.SELECT;
      } else if (this.fieldProperties.type === 'int') {
        fieldType = this.fieldTypes.INTEGER;
      } else if (this.fieldProperties.type === 'float') {
        fieldType = this.fieldTypes.FLOAT;
      } else if (this.fieldProperties.type === 'bool') {
        fieldType = this.fieldTypes.BOOLEAN;
      } else if (this.fieldProperties.type === 'datetime') {
        fieldType = this.fieldTypes.DATETIME;
      } else if (this.fieldProperties.type === 'datetime') {
        fieldType = this.fieldTypes.DATETIME;
      } else if (this.fieldProperties.type === 'object') {
        if (this.fieldProperties.customImplementation) {
          fieldType = this.fieldTypes.CUSTOM;
        } else {
          fieldType = this.fieldTypes.OBJECT;
        }
      }
      if (fieldType === this.fieldTypes.SELECT && this.fieldProperties.isNestedValues) {
        fieldType = this.fieldTypes.NESTED_SELECT;
      }
      return fieldType;
    },
    selectValues() {
      if (this.fieldProperties.type === 'string' && this.fieldProperties.allowableValues) {
        return this.fieldProperties.allowableValues;
      }
      return this.fieldProperties.values;
    },
    label() {
      let label = this.fieldProperties.title
        ? this.fieldProperties.title
        : this.fieldName;
      if (this.fieldProperties.required) {
        label += ' (required)';
      }
      return label;
    },
  },
  methods: {
    prepareValue(value) {
      if (Array.isArray(value)) {
        return value.length ? value : null;
      }
      return value || (typeof value === 'boolean') ? value : null;
    },
    isWrappable(structureItem) {
      let wrappable = structureItem.type === 'object';
      if (structureItem.type === 'object'
          && Object.prototype.hasOwnProperty.call(structureItem, 'wrappable')
      ) {
        wrappable = structureItem.wrappable;
      }
      return wrappable;
    },
  },
};
</script>
