<template>
  <v-list-item
      :class="{ 'active-item': isActive, 'mr-2': nestingNumber === 0 }"
      @click="$emit('click')"
  >
    <v-list-item-icon v-if="icon">
      <v-icon>{{ icon }}</v-icon>
    </v-list-item-icon>

    <item-title
        :title="title"
        :show-count="showCount"
        :count="count"
        :required="required"
        :has-errors="hasErrors"
    />
  </v-list-item>
</template>
<script>
import ItemTitle from '@/components/Neris/Navigation/ItemTitle';

export default {
  name: 'Item',
  components: { ItemTitle },
  props: {
    title: {
      type: String,
      required: true,
    },
    showCount: {
      type: Boolean,
      required: true,
    },
    count: {
      type: Number,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    required: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    nestingNumber: {
      type: Number,
      default: 0,
    },
    hasErrors: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
