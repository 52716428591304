<template>
  <div>
    <i>Click on the map to set the location:</i><br />
    <div
        ref="googleMap"
        class="google-map mb-3"
    />
    <v-toolbar
      class="mb-3"
      flat
    >
      Latitude: {{ innerValue.length ? innerValue[0] : 'None' }}<br />
      Longitude: {{ innerValue.length ? innerValue[1] : 'None' }}<br />
      <v-spacer/>
      <v-btn
        :disabled="!marker"
        @click="deleteMarker"
      >Delete Marker</v-btn>
    </v-toolbar>
    <div
        v-if="false"
        class="mb-3"
      >
      Has Errors
    </div>
  </div>
</template>
<script>
/* eslint-disable no-undef */
export default {
  name: 'GeoPoint',
  props: {
    value: {
      required: false,
    },
    structure: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
      map: null,
      marker: null,
    };
  },
  computed: {
    innerValue: {
      get() {
        if (!this.value || !this.value.geometry || !this.value.geometry.coordinates) {
          return [];
        }
        return this.value.geometry.coordinates;
      },
      set(val) {
        let value = null;
        if (val && val[0] && val[1]) {
          value = {
            crs: 4326,
            geometry: {
              type: 'Point',
              coordinates: [
                val[0],
                val[1],
              ],
            },
          };
        }
        this.$emit('input', value);
      },
    },
  },
  watch: {
    value: {
      handler(value) {
        let latLng = null;
        if (value && value.geometry
            && value.geometry.coordinates
            && value.geometry.coordinates.length
        ) {
          latLng = new google.maps.LatLng(
            value.geometry.coordinates[0],
            value.geometry.coordinates[1],
          );
        }
        return this.setMarker(latLng);
      },
      deep: true,
    },
  },
  mounted() {
    this.initMap();
  },
  methods: {
    deleteMarker() {
      this.innerValue = [];
    },
    initMap() {
      const centerLatLng = new google.maps.LatLng(
        this.innerValue.length ? this.innerValue[0] : 34.052235,
        this.innerValue.length ? this.innerValue[1] : -118.243683,
      );

      this.map = new google.maps.Map(this.$refs.googleMap, {
        zoom: this.innerValue.length ? 15 : 10,
        center: centerLatLng,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });

      const latLng = this.innerValue.length ? new google.maps.LatLng(
        this.innerValue[0],
        this.innerValue[1],
      ) : null;

      if (latLng) {
        this.setMarker(latLng);
      }

      google.maps.event.addListener(this.map, 'click', (event) => {
        this.innerValue = [
          Number((event.latLng.lat()).toFixed(6)),
          Number((event.latLng.lng()).toFixed(6)),
        ];
      });
    },
    setMarker(latLng) {
      if (this.marker) {
        this.marker.setMap(null);
        this.marker = null;
      }
      if (latLng) {
        this.marker = new google.maps.Marker({
          position: latLng,
          map: this.map,
        });
      }
    },
  },
};
</script>
<style>
.neris-form {
  .google-map {
    height: calc(100vh - 290px);
  }
}
</style>
