<template>
  <v-text-field
      v-model="innerValue"
      :label="label"
      :placeholder="placeholder"
      :disabled="disabled"
      :readonly="readonly"
      :error-messages="errorMessages"
      :outlined="outlined"
      type="number"
      @keydown="allowOnlyIntegers"
      @blur="onBlur"
  />
</template>

<script>
export default {
  name: 'IntegerField',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
  },
  emits: ['input', 'blur'],
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    allowOnlyIntegers(event) {
      const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab'];
      if (
        !/^[0-9]$/.test(event.key)
          && !allowedKeys.includes(event.key)
          && !(event.key === '-' && this.innerValue === '')
      ) {
        event.preventDefault();
      }
    },
    onBlur() {
      this.$emit('blur', this.innerValue);
    },
  },
};
</script>
