<template>
  <div>
    <v-dialog
      v-model="showEditPopup"
      persistent
      max-width="700"
    >
      <v-card :class="$style.card">
        <v-card-title class="headline pt-0">
          {{ dataType === 'default' ?
          $t('profileSettings.billingExport.header') :
          $t('profileSettings.billingExportSecondary.header')
          }}
        </v-card-title>
        <v-card-text class="pt-0">
          <v-text-field
            v-model="exportSettings.name"
            v-validate="{ required: true }"
            name="name"
            maxlength="100"
            :error-messages="errors.collect('name')"
            :label="$t('profileSettings.billingExport.name')"
          />
          <v-checkbox
            v-model="exportSettings.isActive"
            :class="$style.checkboxField"
            :label="$t('profileSettings.billingExport.active')"
            name="isActive"
          />
          <v-form
            :class="$style.emailInputContainer"
            @submit.prevent="addEmail"
          >
            <v-text-field
              v-model="newEmail"
              v-validate="{ email: true }"
              color="blue darken-2"
              type="email"
              name="newEmail"
              autocomplete="email"
              :label="$t('profileSettings.newEmailBtn')"
              :error-messages="errors.collect('newEmail')"
            ></v-text-field>
            <v-btn
              color="error"
              type="submit"
              :class="$style.emailAddButton"
            >
              {{ $t('buttons.add') }}
            </v-btn>
          </v-form>
          <div :class="$style.emailsContainer">
            <span
              v-if="showEmailsValidationError"
              :class="$style.error"
            >
              At least one email is required.
            </span>
            <v-chip
              v-for="email in exportSettings.notificationEmails"
              :key="email"
              :class="$style.emailChip"
              close
              @click:close="showDeleteEmailPopup(email)"
            >
              {{ email }}
            </v-chip>
          </div>
          <v-form
            :id="formId"
            :class="$style.column"
            @submit.prevent="validateAndSave"
          >
            <h4>{{ $t('profileSettings.billingExport.ftpSettingsTitle') }}</h4>
            <v-select
              v-model="exportSettings.exportTypeId"
              v-validate="{ required: true }"
              :items="billingExportTypes"
              :label="$t('profileSettings.billingExport.type')"
              item-value="exportTypeId"
              item-text="name"
              single-line
              name="exportType"
              :error-messages="errors.collect('exportType')"
            />
            <div v-if="!isMedicClipboardSFTP">
              <v-text-field
                v-model="exportSettings.server"
                v-validate="{ required: true, url: true }"
                name="server"
                maxlength="255"
                :label="$t('profileSettings.billingExport.server')"
                :error-messages="errors.collect('server')"
              />
              <v-text-field
                v-model="exportSettings.username"
                v-validate="{ required: true }"
                name="username"
                maxlength="100"
                :error-messages="errors.collect('username')"
                :label="$t('profileSettings.billingExport.user')"
              />
              <v-text-field
                v-if="isPasswordEditMode || isFirstSetupMode"
                v-model="newPassword"
                v-validate="{ required: isFirstSetupMode }"
                :label="$t('profileSettings.billingExport.currentPassword')"
                type="password"
                append-icon="lock"
                persistent-hint
                maxlength="100"
                :error-messages="errors.collect('newPassword')"
                name="newPassword"
                autocomplete="new-password"
                @change="isPasswordChanged = true"
              />
              <v-text-field
                v-if="!isPasswordEditMode && !isFirstSetupMode"
                value="******"
                :label="$t('profileSettings.billingExport.currentPassword')"
                readonly
                append-icon="edit"
                @click:append="changePassword"
              />
              <v-text-field
                v-model="exportSettings.uploadDirectory"
                v-validate="{ required: true }"
                name="uploadDirectory"
                maxlength="255"
                :error-messages="errors.collect('uploadDirectory')"
                :label="$t('profileSettings.billingExport.uploadDirectory')"
              />
            </div>
            <h4>{{ $t('profileSettings.billingExport.exportSettingsTitle') }}</h4>
            <v-flex :class="$style.row">
              <div :class="$style.column">
                <v-checkbox
                  v-model="exportSettings.includeUpdatedIncidents"
                  :class="$style.checkboxField"
                  :label="$t('profileSettings.billingExport.updatedIncidents')"
                  name="includeUpdatedIncidents"
                />
                <v-checkbox
                  v-model="exportSettings.noTransport"
                  :class="$style.checkboxField"
                  :label="$t('profileSettings.billingExport.noTransport')"
                  name="noTransport"
                />
                <v-checkbox
                  v-model="exportSettings.withoutAddress"
                  :class="$style.checkboxField"
                  :label="$t('profileSettings.billingExport.withoutAddress')"
                  name="withoutAddress"
                />
                <v-checkbox
                  v-model="exportSettings.unknownPatient"
                  :class="$style.checkboxField"
                  :label="$t('profileSettings.billingExport.unknownPatient')"
                  name="unknownPatient"
                />
                <v-checkbox
                  v-model="exportSettings.emptyPatientName"
                  :class="$style.checkboxField"
                  :label="$t('profileSettings.billingExport.emptyPatientName')"
                  name="emptyPatientName"
                />
              </div>
              <v-radio-group
                v-model="exportSettings.format"
                v-validate="{ required: true }"
                :label="$t('profileSettings.billingExport.format')"
                :class="$style.exportFormat"
                name="format"
                :error-messages="errors.collect('format')"
              >
                <v-radio
                  v-for="format in formats"
                  :key="format.value"
                  :label="`PDF's with ${format.name} file`"
                  :value="format.value"
                ></v-radio>
              </v-radio-group>
            </v-flex>
            <v-autocomplete
                v-model="exportSettings.excludedProviders"
                :items="providersItems"
                :label="$t('profileSettings.billingExport.excludeProviders')"
                multiple
                chips
                deletable-chips
            />

            <v-text-field
              v-validate="{ required: !exportSettings.cron }"
              :value="exportSettings.cron"
              :label="$t('profileSettings.timeForExport')"
              readonly
              name="cron"
              :error-messages="errors.collect('cron')"
              append-icon="edit"
              @click:append="isCronEditMode = !isCronEditMode"
            />
            <VueCronEditorBuefy
              v-if="isCronEditMode || isFirstSetupMode"
              v-model="exportSettings.cron"
              :visibleTabs="['minutes', 'hourly', 'daily', 'weekly', 'monthly', 'advanced']"
              :preserveStateOnSwitchToAdvanced="true"
            />
            <v-checkbox
              v-model="exportSettings.useCompression"
              :label="$t('profileSettings.billingExport.useCompression')"
              name="useCompression"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="pb-0 pt-0">
          <v-spacer/>
          <v-btn
            color="blue darken-1"
            text
            @click.native="showEditPopup = false"
          >{{ $t('buttons.cancel') }}</v-btn>
          <v-btn
            color="primary"
            text
            :form="formId"
            type="submit"
          >{{ $t('buttons.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirmation ref="confirmationPopup"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { UPDATE_BILLING_EXPORT_SETTINGS } from '@/store/ProfileSettings/actions';
import lookupApi from '@/api/lookup';
import editExportPopupMixin from './editExportPopupMixin';

export default {
  name: 'EditBillingExportPopup',
  mixins: [editExportPopupMixin],
  data() {
    return {
      /**
       * Form id for submit by button.
       */
      formId: 'billingExportForm',
      /**
       * Value of new email.
       */
      newEmail: '',
      /**
       * Data type: Default and Secondary
       */
      dataType: 'default',
      /**
       * Flag for show emails list validation error.
       */
      showEmailsValidationError: false,
      /**
       * Formats types list.
       */
      formats: [
        { value: 'xml', name: 'XML' },
        { value: 'tab', name: 'TAB' },
        { value: 'tripToken', name: 'Trip Token' },
      ],

      providers: [],
      /**
       * Export settings model.
       */
      exportSettings: {
        notificationEmails: [],
        exportTypeId: null,
        server: '',
        username: '',
        uploadDirectory: '',
        includeUpdatedIncidents: false,
        noTransport: false,
        withoutAddress: false,
        unknownPatient: false,
        emptyPatientName: false,
        excludedProviders: null,
        format: null,
        cron: '',
        useCompression: false,
        dataType: 'default',
        exportId: null,
        name: '',
        isActive: true,
      },
    };
  },
  computed: {
    ...mapState('profileSettings', {
      billingExportSettings: state => state.billingExportSettings,
      billingExportSecondarySettings: state => state.billingExportSecondarySettings,
      billingExportTypes: state => state.billingExportUploadTypes,
    }),
    /**
     * Check export type id is the id of medic clipboard sftp type.
     */
    isMedicClipboardSFTP() {
      const MEDIC_CLIPBOARD_SFTP_TYPE_ID = 5;

      return this.exportSettings.exportTypeId === MEDIC_CLIPBOARD_SFTP_TYPE_ID;
    },
    /**
     * Providers items.
     */
    providersItems() {
      return this.providers.map(item => ({
        text: `${item.code} - ${item.name}`,
        value: item.code,
      }));
    },
  },
  mounted() {
    this.loadProviders();
  },
  methods: {
    ...mapActions('profileSettings', {
      // Using in editExportPopupMixin
      saveSettings: UPDATE_BILLING_EXPORT_SETTINGS,
    }),

    /**
     * Load list of providers.
     */
    async loadProviders() {
      const response = await lookupApi.fetchProviders();
      this.providers = response.results;
    },

    /**
     * Open popup for edit.
     */
    async showPopup(type) {
      this.initPopupData();
      let setting = null;
      this.dataType = type;

      if (type === 'default' && this.billingExportSettings) {
        setting = this.billingExportSettings;
      } else if (type === 'secondary' && this.billingExportSecondarySettings) {
        setting = this.billingExportSecondarySettings;
      }

      if (setting != null) {
        this.isFirstSetupMode = false;
        this.exportSettings = {
          notificationEmails: setting.notificationEmails,
          exportTypeId: setting.exportTypeId,
          server: setting.server,
          username: setting.username,
          uploadDirectory: setting.uploadDirectory,
          includeUpdatedIncidents: setting.includeUpdatedIncidents,
          noTransport: setting.noTransport,
          withoutAddress: setting.withoutAddress,
          unknownPatient: setting.unknownPatient,
          emptyPatientName: setting.emptyPatientName,
          excludedProviders: setting.excludedProviders,
          format: setting.format,
          cron: setting.cron,
          useCompression: setting.useCompression,
          dataType: type,
          exportId: setting.exportId,
          name: setting.name,
          isActive: setting.isActive,
        };
      } else {
        this.isFirstSetupMode = true;
        this.exportSettings = {
          notificationEmails: [],
          exportTypeId: null,
          server: '',
          username: '',
          uploadDirectory: '',
          includeUpdatedIncidents: false,
          noTransport: false,
          withoutAddress: false,
          unknownPatient: false,
          emptyPatientName: false,
          excludedProviders: null,
          format: null,
          cron: '',
          useCompression: false,
          dataType: type,
          exportId: null,
          name: '',
          isActive: true,
        };
      }
    },
    /**
     * Add email to notification emails list.
     */
    async addEmail() {
      const isNewEmailValid = await this.$validator.validate('newEmail');
      if (this.newEmail === '' || !isNewEmailValid) {
        return;
      }

      this.showEmailsValidationError = false;
      this.exportSettings.notificationEmails.push(this.newEmail);
      this.newEmail = '';
    },
    /**
     * Remove email from notification emails list.
     */
    deleteEmail(emailToDelete) {
      this.exportSettings.notificationEmails = this.exportSettings.notificationEmails
        .filter(email => email !== emailToDelete);
    },
    /**
     * Show confirmation for deleting email.
     * @param email
     */
    showDeleteEmailPopup(email) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('profileSettings.deleteEmailTitle'),
        `Do you want to delete ${email} from list?`,
        () => {
          this.deleteEmail(email);
        },
      );
    },
    /**
     * Check for emails list validation and save in success case.
     */
    async validateAndSave() {
      this.showEmailsValidationError = this.exportSettings.notificationEmails.length === 0;

      if (!await this.$validator.validateAll() || this.showEmailsValidationError) {
        return;
      }

      await this.save();
    },
  },
};
</script>

<style lang="scss" module>
.card {
  padding: 14px;
}

.row {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
}

.error {
  color: var(--v-error-base);
  margin-bottom: 10px;
}

.checkboxField {
  margin-top: 0;
}

.exportFormat {
  margin: 10px 0 0 26px;
}

.emailInputContainer {
  display: flex;
  align-items: center;
}

.emailsContainer {
  display: flex;
  flex-wrap: wrap;
}

.emailAddButton {
  margin-left: 10px;
}

.emailChip {
  flex-shrink: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
