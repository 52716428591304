<template>
  <div>
    <v-text-field
        v-model="innerValueFormatted"
        :label="label"
        :error-messages="errorMessages"
        :outlined="outlined"
        prepend-inner-icon="event"
        clearable
        name="scheduledDate"
        readonly
        @click="openDatePicker"
    />
    <datetime
        ref="dateTime"
        v-model="innerValue"
        type="datetime"
        input-class="d-none"
        class="theme-red"
        format="MM-DD-YYYY"
        zone="UTC"
        use12-hour
    />
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { Datetime } from 'vue-datetime';

export default {
  name: 'DateTimeField',
  components: {
    Datetime,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
  },
  computed: {
    innerValue: {
      get() {
        return this.value
          ? moment.parseZone(this.value, 'YYYY-MM-DD HH:mm:ss').toISOString()
          : null;
      },
      set(value) {
        const val = value
          ? moment.parseZone(value).format('YYYY-MM-DD HH:mm:ss')
          : null;
        this.$emit('input', val);
      },
    },
    /**
     * Computed property for schedule date input model.
     */
    innerValueFormatted: {
      get() {
        return this.innerValue
          ? moment.parseZone(this.innerValue).format('MM/DD/YYYY h:mm A')
          : null;
      },
      set(value) {
        this.innerValue = value;
      },
    },
  },
  methods: {
    /**
     * Handler of click by schedule date input.
     */
    openDatePicker(event) {
      this.$refs.dateTime.open(event);
    },
  },
};
</script>
