<template>
  <v-list :color="color">
    <item-or-group
        v-for="category in categories"
        :key="'navigation_item_' + category.id"
        :current-category="currentCategory"
        :category="category"
        :form-data="formData"
        :nesting-number="0"
        :error-messages="getErrorMessages(category)"
        @chooseCategory="chooseCategory"
    />
  </v-list>
</template>
<script>
import _ from 'lodash';
import ItemOrGroup from '@/components/Neris/Navigation/ItemOrGroup';
import navigationMixin from './navigationMixin';

export default {
  components: {
    ItemOrGroup,
  },
  name: 'Navigation',
  mixins: [navigationMixin],
  props: {
    categories: {
      type: Object,
      required: true,
    },
    currentCategory: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: undefined,
    },
    errorMessages: {
      type: Object,
      required: true,
    },
  },
  methods: {
    countSubcategories(category) {
      if (!category.subcategories) {
        return 0;
      }
      return Object.keys(category.subcategories).length;
    },

    chooseCategory(category) {
      if (category.type === 'category') {
        this.$emit('chooseCategory', category);
      }
    },

    getErrorMessages(category) {
      let errorMessages = {};
      if (category.field) {
        errorMessages = this.getByObjectPath(this.errorMessages, category.field);
      } else if (category.type === 'category') {
        _.forEach(category.structure, (item, field) => {
          errorMessages[field] = this.getByObjectPath(this.errorMessages, field);
        });
      } else if (category.type === 'group') {
        _.forEach(category.subcategories, (item, field) => {
          errorMessages[field] = this.getByObjectPath(this.errorMessages, field);
        });
      }
      return errorMessages || {};
    },
  },
};
</script>
