import actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    reportNotification: {
      emails: [],
      time: null,
    },
    deviceNotification: {
      rules: [],
      time: null,
    },
    transmittedNotification: {
      rules: [],
      time: null,
    },
    billingExportSettings: null,
    billingExportSecondarySettings: null,
    unfilteredExportSettings: null,
    billingExportUploadTypes: null,
    dhsExportUploadTypes: null,
    dhsExportSettings: null,
    mindBaseExportSettings: null,
    securitySettings: {
      apiKeySettings: null,
      accessRestrictions: null,
    },
    deletedUniqueForms: {
      emails: [],
      frequency: null,
    },
    ceAdminNotifications: [],
  },
  actions,
  mutations,
};
