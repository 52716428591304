const incident = {
  main: {
    title: 'Incident',
    type: 'category',
    icon: 'mdi-alarm-light-outline',
    field: null,
    subcategories: null,
    expandable: true,
    structure: {},
    fieldProperties: {
      isArray: false,
      required: true,
    },
  },
  base: {
    type: 'category',
    field: 'base',
    subcategories: null,
    expandable: true,
    structure: {},
    fieldProperties: {
      isArray: false,
    },
  },
  dispatch: {
    type: 'category',
    field: 'dispatch',
    subcategories: null,
    expandable: true,
    structure: {},
    fieldProperties: {
      isArray: false,
    },
  },
  fire: {
    type: 'group',
    title: 'Fire',
    field: null,
    subcategories: {
      fire_detail: {
        type: 'category',
        field: 'fire_detail',
        subcategories: null,
        expandable: true,
        structure: {},
        fieldProperties: {
          isArray: false,
        },
      },
      fire_alarm: {
        type: 'category',
        field: 'fire_alarm',
        subcategories: null,
        expandable: false,
        structure: {},
        fieldProperties: {
          isArray: false,
        },
      },
      fire_suppression: {
        type: 'category',
        field: 'fire_suppression',
        subcategories: null,
        expandable: false,
        structure: {},
        fieldProperties: {
          isArray: false,
        },
      },
      smoke_alarm: {
        type: 'category',
        field: 'smoke_alarm',
        subcategories: null,
        expandable: false,
        structure: {},
        fieldProperties: {
          isArray: false,
        },
      },
      other_alarm: {
        type: 'category',
        field: 'other_alarm',
        subcategories: null,
        expandable: false,
        structure: {},
        fieldProperties: {
          isArray: false,
        },
      },
      cooking_fire_suppression: {
        type: 'category',
        field: 'cooking_fire_suppression',
        subcategories: null,
        expandable: false,
        structure: {},
        fieldProperties: {
          isArray: false,
        },
      },
    },
    expandable: true,
    structure: {},
    fieldProperties: {
      isArray: false,
    },
  },
  hazards: {
    type: 'group',
    title: 'Hazards',
    field: null,
    subcategories: {
      electric_hazards: {
        type: 'category',
        field: 'electric_hazards',
        subcategories: null,
        expandable: false,
        structure: {},
        fieldProperties: {
          isArray: false,
        },
      },
      powergen_hazards: {
        type: 'category',
        field: 'powergen_hazards',
        subcategories: null,
        expandable: false,
        structure: {},
        fieldProperties: {
          isArray: false,
        },
      },
      csst_hazard: {
        type: 'category',
        field: 'csst_hazard',
        subcategories: null,
        expandable: false,
        structure: {},
        fieldProperties: {
          isArray: false,
        },
      },
    },
    expandable: true,
    structure: {},
    fieldProperties: {
      isArray: false,
    },
  },
  actions_tactics: {
    type: 'category',
    field: 'actions_tactics',
    subcategories: null,
    expandable: false,
    structure: {},
    fieldProperties: {
      isArray: false,
    },
  },
};

const entity = {
  main: {
    title: 'Department',
    type: 'category',
    icon: 'mdi-heart-pulse',
    field: null,
    subcategories: null,
    expandable: true,
    structure: {},
    fieldProperties: {
      isArray: false,
    },
  },
};

const preDefinedCategories = {
  incident,
  entity,
};

export default preDefinedCategories;
