import api from './api';

export default {
  /**
   * Fetch list for table.
   *
   * @param {String} type - Type of export "billing" or "dhs"
   * @param {String} billingDataType - Billing data type "default" or "secondary"
   * @param {Number} limit - Return total of records
   *
   * @return Promise<Object>
   */
  fetchExportLog(type, billingDataType, limit) {
    return api()
      .get(`/client/export-log/${type}`, {
        params: type === 'billing' ? {
          per_page: limit,
          type: billingDataType,
        } : {
          per_page: limit,
        },
      })
      .then(response => response.data);
  },
  /**
   * Fetch totals of incidents.
   *
   * @param {String} type - Type of export "billing" or "dhs"
   * @param {String} billingDataType - Billing data type "default" or "secondary"
   *
   * @return Promise<Object>
   */
  runManually(type, billingDataType) {
    return api()
      .post(`/client/export-log/${type}/run`, type === 'billing' ? {
        type: billingDataType,
      } : {})
      .then(response => response.data);
  },

  /**
   * Fetch totals of incidents.
   *
   * @param {String} type - Type of export "billing" or "dhs"
   * @param {String} billingDataType - Billing data type "default" or "secondary"
   *
   * @return Promise<Object>
   */
  fetchExportLogTotals(type, billingDataType) {
    return api()
      .get(`/client/export-log/${type}/totals`, {
        params: type === 'billing' ? {
          type: billingDataType,
        } : {},
      })
      .then(response => response.data);
  },

  /**
   * Fetch totals not sent incidents to billing.
   *
   * @param {String} billingDataType - Billing data type "default" or "secondary"
   *
   * @return Promise<Object>
   */
  fetchBillingNotSentTotals(billingDataType) {
    return api()
      .get('/client/export-log/billing/totals-not-sent', {
        params: {
          type: billingDataType,
        },
      })
      .then(response => response.data);
  },

  /**
  * Fetch list of table.
  *
  * @param {Number} logId - Log identifier
  *
  * @return Promise<Object>
  */
  fetchExportLogData(logId) {
    return api()
      .get(`/client/export-log/${logId}`, {
        params: {
          include: 'exportIncidents',
        },
      })
      .then(response => response.data);
  },
};
