<template>
  <v-list-item-title>
    {{ title }}
    <span v-if="showCount">
       ({{ count }})
    </span>
    <span v-if="required">*</span>
    <v-icon
      v-if="hasErrors"
      class="inline_span ml-3"
      color="red"
      dense
    >
      mdi-alert-circle
    </v-icon>
  </v-list-item-title>
</template>
<script>
export default {
  name: 'ItemTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    showCount: {
      type: Boolean,
      required: false,
      default: false,
    },
    count: {
      type: Number,
      required: false,
      default: 0,
    },
    required: {
      type: Boolean,
      required: true,
    },
    hasErrors: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
