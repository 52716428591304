<template>
  <div
    v-if="loading"
    class="text-center"
  >
    <v-progress-circular
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
  <div v-else>
    <v-container>
      <NotificationsSettings
        @showEditTransmittedNotificationPopup="showEditTransmittedNotificationPopup"
        @showEditDeviceNotificationPopup="showEditDeviceNotificationPopup"
      />

      <ExportsSettings
        :show-unfiltered-export="true"
        :show-mind-base-export="true"
        @showEditBillingExportPopup="showEditBillingExportPopup"
        @showEditBillingExportSecondaryPopup="showEditBillingExportSecondaryPopup"
        @showEditDhsExportPopup="showEditDhsExportPopup"
        @showEditUnfilteredExportPopup="showEditUnfilteredExportPopup"
        @showEditAccessRestriction="showEditAccessRestriction"
        @showEditMindBaseExportPopup="showEditMindBaseExportPopup"
      />
    </v-container>

    <EditDeviceNotificationPopup ref="editDeviceNotificationPopup" />
    <EditTransmittedNotificationPopup ref="editTransmittedNotificationPopup" />
    <EditDhsExportPopup ref="editDhsExportPopup" />
    <EditBillingExportPopup ref="editBillingExportPopup" />
    <EditUnfilteredExportPopup ref="editUnfilteredExportPopup" />
    <EditMindBaseExportPopup ref="editMindBaseExportPopup" />
    <EditSecurityPopup
      ref="editSecurityPopup"
      @saved="getAccessRestrictions"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import NotificationsSettings from '@/components/ProfileSettings/NotificationsSettings';
import ExportsSettings from '@/components/ProfileSettings/ExportsSettings';
import EditDeviceNotificationPopup from '@/components/ProfileSettings/EditDeviceNotificationPopup';
import EditTransmittedNotificationPopup from '@/components/ProfileSettings/EditTransmittedNotificationPopup';
import EditDhsExportPopup from '@/components/ProfileSettings/EditDhsExportPopup';
import EditBillingExportPopup from '@/components/ProfileSettings/EditBillingExportPopup';
import EditSecurityPopup from '@/components/ProfileSettings/EditSecurityPopup';
import { SET_TITLE } from '@/store/mutations';
import {
  GET_ALL_SETTINGS,
  GET_BILLING_EXPORT_UPLOAD_TYPES,
  GET_DHS_EXPORT_UPLOAD_TYPES,
  GET_ACCESS_RESTRICTIONS,
} from '@/store/ProfileSettings/actions';
import EditUnfilteredExportPopup from '@/components/ProfileSettings/EditUnfilteredExportPopup';
import EditMindBaseExportPopup from '@/components/ProfileSettings/EditMindBaseExportPopup';

export default {
  name: 'ProfileSettings',
  components: {
    EditMindBaseExportPopup,
    EditUnfilteredExportPopup,
    EditSecurityPopup,
    EditDhsExportPopup,
    EditBillingExportPopup,
    EditDeviceNotificationPopup,
    EditTransmittedNotificationPopup,
    ExportsSettings,
    NotificationsSettings,
  },
  mounted() {
    this.$store.commit(SET_TITLE, 'Profile Settings');
    this.fetchData();
  },
  data() {
    return {
      loading: true,
    };
  },
  methods: {
    ...mapActions('profileSettings', {
      getSettings: GET_ALL_SETTINGS,
      getBillingExportUploadTypes: GET_BILLING_EXPORT_UPLOAD_TYPES,
      getDHSExportUploadTypes: GET_DHS_EXPORT_UPLOAD_TYPES,
      getAccessRestrictions: GET_ACCESS_RESTRICTIONS,
    }),
    async fetchData() {
      try {
        this.loading = true;
        await Promise.all([
          this.getBillingExportUploadTypes(),
          this.getDHSExportUploadTypes(),
          this.getSettings(),
        ]);
      } finally {
        this.loading = false;
      }
    },
    showEditDeviceNotificationPopup(item) {
      this.$refs.editDeviceNotificationPopup.showPopup(item);
    },
    showEditTransmittedNotificationPopup(item) {
      this.$refs.editTransmittedNotificationPopup.showPopup(item);
    },
    showEditBillingExportPopup() {
      this.$refs.editBillingExportPopup.showPopup('default');
    },
    showEditBillingExportSecondaryPopup() {
      this.$refs.editBillingExportPopup.showPopup('secondary');
    },
    showEditUnfilteredExportPopup() {
      this.$refs.editUnfilteredExportPopup.showPopup();
    },
    showEditDhsExportPopup() {
      this.$refs.editDhsExportPopup.showPopup();
    },
    showEditMindBaseExportPopup() {
      this.$refs.editMindBaseExportPopup.showPopup();
    },
    /**
     * Show dialog for Security Settings Access Restriction item editing.
     * If item for editing is not passed it means that creating of new item is requested.
     * @param {object} [item] Item to edit.
     */
    showEditAccessRestriction(item) {
      this.$refs.editSecurityPopup.showPopup(item);
    },
  },
};
</script>
