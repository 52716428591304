<template>
  <nested-select-field
      v-model="innerValue"
      :label="label"
      :items="structure.type.values"
      :error-messages="errorMessages || []"
      multiple
      outlined
      clearable
  />
</template>
<script>
import NestedSelectField from '@/components/Neris/Controls/NestedSelectField';

export default {
  name: 'IncidentTypePayload',
  components: { NestedSelectField },
  props: {
    value: {
      required: false,
    },
    structure: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: [Object, Array],
      required: true,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    innerValue: {
      get() {
        if (!this.value) {
          return [];
        }
        const primaryTypes = this.value
          .filter(item => !!item.primary)
          .map(item => item.type);
        const secondaryTypes = this.value
          .filter(item => !item.primary)
          .map(item => item.type);
        return primaryTypes.concat(secondaryTypes);
      },
      set(val) {
        if (!val || val.length === 0) {
          this.$emit('input', null);
          return;
        }
        const result = [];
        val.forEach((item, index) => {
          result.push({
            primary: index === 0,
            type: item,
          });
        });
        this.$emit('input', result);
      },
    },
  },
};
</script>
