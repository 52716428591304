<template>
  <v-autocomplete
      v-model="innerValue"
      :label="label"
      :disabled="disabled"
      :readonly="readonly"
      :error-messages="errorMessages"
      :outlined="outlined"
      :items="itemsPrepared"
      :multiple="multiple"
  >
    <template v-slot:selection="{ item }">
      <v-chip
          v-if="multiple"
          close
          class="chip--select-multi"
          @click:close="remove(item.value)"
      >
        {{ item.fullName }}
      </v-chip>
      <span v-else>
        {{ item.fullName }}
      </span>
    </template>
    <template v-slot:item="{ item }">
      <template>
        <v-list-item-content>
          <v-list-item-title
            :class="[
                'pl-' + (item.level * 4),
                item.disabled ? 'font-weight-bold' : ''
            ]"
          >
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'NestedSelectField',
  props: {
    value: {
      type: [String, Array],
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
  },
  emits: ['input', 'blur'],
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    itemsPrepared() {
      return this.prepareItems();
    },
  },
  methods: {
    prepareItems() {
      const structure = {
        selectable: false,
        value: null,
        level: null,
        items: {},
      };
      this.items.forEach(item => {
        const parts = item.split('||');
        let object = structure;
        let level = 0;
        parts.forEach(value => {
          if (!object.items || !Object.prototype.hasOwnProperty.call(object.items, value)) {
            if (!object.items) {
              object.items = {};
            }
            object.items[value] = {
              selectable: false,
              level,
              value: null,
              items: null,
            };
          }
          level++;
          object = object.items[value];
        });
        object.selectable = true;
        object.value = item;
      });
      let items = [];
      _.forEach(structure.items, (item, name) => {
        const itemsToMerge = this.parseStructureRecursive(item, name);
        items = items.concat(itemsToMerge);
        items.push({ divider: true });
      });
      return items;
    },

    parseStructureRecursive(item, title) {
      let items = [];
      const name = this.prepareName(title);
      const menuItem = { text: name, level: item.level };
      if (item.selectable) {
        menuItem.value = item.value;
        menuItem.fullName = this.prepareFullName(item.value);
      } else {
        menuItem.disabled = true;
      }
      items.push(menuItem);
      if (item.items) {
        _.forEach(item.items, (itemNested, nameNested) => {
          const itemsToMerge = this.parseStructureRecursive(itemNested, nameNested);
          items = items.concat(itemsToMerge);
        });
      }
      return items;
    },

    prepareName(name) {
      return name.toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    prepareFullName(name) {
      return name.toLowerCase()
        .split('||')
        .join(' / ')
        .split('_')
        .join(' ')
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },

    remove(value) {
      const values = _.cloneDeep(this.innerValue);
      const index = values.indexOf(value);
      if (index !== -1) {
        values.splice(index, 1);
      }
      this.innerValue = values;
    },
  },
};
</script>
