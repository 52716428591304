<template>
  <v-row>
    <v-col
      xl="3"
      lg="6"
    >
    <!--Billing Export settings-->
    <v-card
      elevation="3"
      class="ma-4"
      :class="$style.card"
    >
      <div :class="$style.row">
        <h3> {{ $t('profileSettings.billingExport.header') }}</h3>
        <v-btn
          v-if="billingExportSettings"
          text
          color="primary"
          @click="$emit('showEditBillingExportPopup')"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn
          v-if="billingExportSettings && billingExportSecondarySettings === null"
          color="primary"
          dark
          class="mb-2 ml-auto"
          @click="$emit('showEditBillingExportSecondaryPopup')"
        >+ ADD
        </v-btn>
      </div>
      <div v-if="billingExportSettings">
        <div :class="$style.column">
          <h4>{{ $t('profileSettings.billingExport.name') }}
            <span :class="$style.redLabel">
              ({{ billingExportSettings.isActive ? 'Active' : 'Inactive' }})
            </span>
          </h4>
          <span>{{ billingExportSettings.name }}</span>
        </div>
        <div :class="$style.column">
          <h4>{{ $t('profileSettings.billingExport.emailNotificationAfterUploadTitle') }}</h4>
          <span>{{ billingExportNotificationEmails }}</span>
        </div>

        <div
          v-if="!isMedicClipboardSFTP"
          :class="$style.column"
        >
          <h4>{{ $t('profileSettings.billingExport.ftpSettingsTitle') }}</h4>
          <label>{{ $t('profileSettings.billingExport.server') }}
            <span>{{ billingExportSettings.server }}</span>
          </label>
          <label>{{ $t('profileSettings.billingExport.user') }}
            <span>{{ billingExportSettings.username }}</span>
          </label>
          <label>{{ $t('profileSettings.billingExport.currentPassword') }}
            <span>******</span>
          </label>
          <label>{{ $t('profileSettings.billingExport.uploadDirectory') }}
            <span>{{ billingExportSettings.uploadDirectory }}</span>
          </label>
        </div>

        <div :class="$style.column">
          <h4>{{ $t('profileSettings.billingExport.exportSettingsTitle') }}</h4>
          <label>{{ $t('profileSettings.timeForExport') }}
            <span>{{ billingExportSettings.cron }}</span>
          </label>
        </div>
      </div>
      <div v-else>
        <v-btn
          :class="$style.exportSetupButton"
          @click="$emit('showEditBillingExportPopup')"
        >
          Setup Export Billing Settings
        </v-btn>
      </div>
    </v-card>
    </v-col>
    <v-col
      v-if="billingExportSecondarySettings"
      xl="3"
      lg="6"
    >
      <!--Billing Export settings - Secondary-->
      <v-card
        elevation="3"
        class="ma-4"
        :class="$style.card"
      >
        <div :class="$style.row">
          <h3> {{ $t('profileSettings.billingExportSecondary.header') }}</h3>
          <v-btn
            v-if="billingExportSecondarySettings"
            text
            color="primary"
            @click="$emit('showEditBillingExportSecondaryPopup')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
        <div v-if="billingExportSecondarySettings">
          <div :class="$style.column">
            <h4>{{ $t('profileSettings.billingExport.name') }}
              <span :class="$style.redLabel">
              ({{ billingExportSecondarySettings.isActive ? 'Active' : 'Inactive' }})
              </span>
            </h4>
            <span>{{ billingExportSecondarySettings.name }}</span>
          </div>
          <div :class="$style.column">
            <h4>{{ $t('profileSettings.billingExport.emailNotificationAfterUploadTitle') }}</h4>
            <span>{{ billingExportNotificationEmailsSecondary }}</span>
          </div>

          <div
            v-if="!isMedicClipboardSFTP"
            :class="$style.column"
          >
            <h4>{{ $t('profileSettings.billingExport.ftpSettingsTitle') }}</h4>
            <label>{{ $t('profileSettings.billingExport.server') }}
              <span>{{ billingExportSecondarySettings.server }}</span>
            </label>
            <label>{{ $t('profileSettings.billingExport.user') }}
              <span>{{ billingExportSecondarySettings.username }}</span>
            </label>
            <label>{{ $t('profileSettings.billingExport.currentPassword') }}
              <span>******</span>
            </label>
            <label>{{ $t('profileSettings.billingExport.uploadDirectory') }}
              <span>{{ billingExportSecondarySettings.uploadDirectory }}</span>
            </label>
          </div>

          <div :class="$style.column">
            <h4>{{ $t('profileSettings.billingExport.exportSettingsTitle') }}</h4>
            <label>{{ $t('profileSettings.timeForExport') }}
              <span>{{ billingExportSecondarySettings.cron }}</span>
            </label>
          </div>
        </div>
        <div v-else>
          <v-btn
            :class="$style.exportSetupButton"
            @click="$emit('showEditBillingExportSecondaryPopup')"
          >
            Setup Export Billing Settings - Secondary
          </v-btn>
        </div>
      </v-card>
    </v-col>
    <v-col
      xl="3"
      lg="6"
    >
      <!--DHS Export settings-->
      <v-card
        elevation="3"
        class="ma-4"
        :class="$style.card"
      >
        <div :class="$style.row">
          <h3>{{ $t('profileSettings.dhsExport.header') }}</h3>
          <v-btn
            v-if="dhsExportSettings"
            text
            color="primary"
            @click="$emit('showEditDhsExportPopup')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
        <div v-if="dhsExportSettings">
          <div :class="$style.column">
            <h4>{{ $t('profileSettings.dhsExport.exportDestinationTitle') }}</h4>
            <label>{{ $t('profileSettings.dhsExport.type') }}
              <span>{{ exportTypeName }}</span>
            </label>
            <label>{{ $t('profileSettings.dhsExport.url') }}
              <span>{{ dhsExportSettings.server }}</span>
            </label>
            <label>{{ $t('profileSettings.dhsExport.username') }}
              <span>{{ dhsExportSettings.username }}</span>
            </label>
          </div>

          <div :class="$style.column">
            <h4>{{ $t('profileSettings.dhsExport.exportSettingsTitle') }}</h4>
            <label>{{ $t('profileSettings.timeForExport') }}
              <span>{{ dhsExportSettings.cron }}</span>
            </label>
          </div>
        </div>
        <div v-else>
          <v-btn
            :class="$style.exportSetupButton"
            @click="$emit('showEditDhsExportPopup')"
          >
            Setup Export DHS Settings
          </v-btn>
        </div>
      </v-card>
    </v-col>

    <v-col
        v-if="showUnfilteredExport && unfilteredExportSettings"
        xl="3"
        lg="6"
    >
      <!--Unfiltered Export settings-->
      <v-card
          elevation="3"
          class="ma-4"
          :class="$style.card"
      >
        <div :class="$style.row">
          <h3> {{ $t('profileSettings.unfilteredExport.header') }}</h3>
          <v-btn
              text
              color="primary"
              @click="$emit('showEditUnfilteredExportPopup')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
        <div>
          <div :class="$style.column">
            <h4>{{ $t('profileSettings.unfilteredExport.ftpSettingsTitle') }}</h4>
            <template v-if="!unfilteredIsMedicClipboardSFTP">
              <label>{{ $t('profileSettings.unfilteredExport.server') }}
                <span>{{ unfilteredExportSettings.server }}</span>
              </label>
              <label>{{ $t('profileSettings.unfilteredExport.user') }}
                <span>{{ unfilteredExportSettings.username }}</span>
              </label>
              <label>{{ $t('profileSettings.unfilteredExport.currentPassword') }}
                <span>******</span>
              </label>
            </template>

            <label>{{ $t('profileSettings.unfilteredExport.uploadDirectory') }}
              <span>{{ unfilteredExportSettings.uploadDirectory }}</span>
            </label>
          </div>

          <div :class="$style.column">
            <h4>{{ $t('profileSettings.unfilteredExport.exportSettingsTitle') }}</h4>
            <label>{{ $t('profileSettings.timeForExport') }}
              <span>{{ unfilteredExportSettings.cron }}</span>
            </label>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col
        v-if="showMindBaseExport"
        xl="3"
        lg="6"
    >
      <!--MindBase Export settings-->
      <v-card
          elevation="3"
          class="ma-4"
          :class="$style.card"
      >
        <div :class="$style.row">
          <h3> {{ $t('profileSettings.mindBaseExport.header') }}</h3>
          <v-btn
              text
              color="primary"
              @click="$emit('showEditMindBaseExportPopup')"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
        <div>
          <div :class="$style.column">
            <h4>{{ $t('profileSettings.mindBaseExport.ftpSettingsTitle') }}</h4>
            <label>{{ $t('profileSettings.mindBaseExport.server') }}
              <span>{{ mindBaseExportSettings ? mindBaseExportSettings.ftpServer : '' }}</span>
            </label>
            <label>{{ $t('profileSettings.mindBaseExport.user') }}
              <span>{{ mindBaseExportSettings ? mindBaseExportSettings.ftpUser : '' }}</span>
            </label>
            <label>{{ $t('profileSettings.mindBaseExport.currentPassword') }}
              <span>{{ mindBaseExportSettings ? '******' : '' }}</span>
            </label>
            <label>{{ $t('profileSettings.mindBaseExport.uploadDirectory') }}
              <span>
                {{ mindBaseExportSettings ? mindBaseExportSettings.uploadDirectory : '' }}
              </span>
            </label>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col
      xl="6"
      lg="12"
    >
      <!-- Security settings-->
      <v-card
        elevation="3"
        class="ma-4"
        :class="$style.card"
      >
        <h3>{{ $t('profileSettings.security.header') }}</h3>
        <div>
          <h4>{{ $t('profileSettings.security.apiAccessTitle') }}</h4>
          <div
            v-if="securitySettingsAPIKey"
            :class="$style.row"
          >
            <v-text-field
              v-model="securitySettingsAPIKey.apiKey"
              persistent-hint
              name="Current API Key"
              readonly
              :class="$style.apiKey"
              :label="$t('profileSettings.security.apiKey')"
              :append-icon="isAPIKeyShow ? 'visibility_off' : 'visibility'"
              :type="isAPIKeyShow ? 'text' : 'password'"
              :hint="apiKeyLastModifiedInfo"
              @click:append="isAPIKeyShow = !isAPIKeyShow"
            ></v-text-field>
            <v-btn
              v-clipboard:copy="securitySettingsAPIKey.apiKey"
              :class="$style.apiKeyButton"
            >
              {{ $t('profileSettings.copyBtn') }}
            </v-btn>
            <v-btn
              :class="$style.apiKeyButton"
              @click="refreshAPIKey"
            >
              {{ $t('profileSettings.regenerateBtn') }}
            </v-btn>
          </div>
          <div v-else>
            <span>No API key</span>
            <v-btn
              :class="$style.apiKeyButton"
              @click="refreshAPIKey"
            >
              Create API key
            </v-btn>
          </div>
        </div>
        <restriction-by-ip-table
            :items="securitySettingsAccessRestrictions"
            @edit="showEditAccessRestriction"
            @delete="showDeleteRowPopup"
        />
      </v-card>
    </v-col>
    <confirmation ref="confirmationPopup"/>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Confirmation from '@/components/Confirmation';
import { UPDATE_API_KEY, DELETE_ACCESS_RESTRICTION, GET_ACCESS_RESTRICTIONS } from '@/store/ProfileSettings/actions';
import RestrictionByIpTable from '@/components/ProfileSettings/RestrictionByIpTable';

export default {
  name: 'ExportSettings',
  components: {
    RestrictionByIpTable,
    Confirmation,
  },
  props: {
    showUnfilteredExport: {
      type: Boolean,
      required: true,
    },
    showMindBaseExport: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      /**
       * Flag for show/hide value of API key field.
       */
      isAPIKeyShow: false,
    };
  },
  computed: {
    ...mapState('profileSettings', {
      billingExportSettings: state => state.billingExportSettings,
      billingExportSecondarySettings: state => state.billingExportSecondarySettings,
      unfilteredExportSettings: state => state.unfilteredExportSettings,
      dhsExportSettings: state => state.dhsExportSettings,
      dhsExportUploadTypes: state => state.dhsExportUploadTypes,
      mindBaseExportSettings: state => state.mindBaseExportSettings,
      securitySettingsAPIKey: state => state.securitySettings.apiKeySettings,
      securitySettingsAccessRestrictions: state => state.securitySettings.accessRestrictions,
    }),
    /**
     * Get export type name by export type id.
     */
    exportTypeName() {
      return this.dhsExportUploadTypes
        ? this.dhsExportUploadTypes
          .find(type => type.exportTypeId === this.dhsExportSettings.exportTypeId)?.name
        : null;
    },
    /**
     * Check export type id is the id of medic clipboard sftp type.
     */
    isMedicClipboardSFTP() {
      const MEDIC_CLIPBOARD_SFTP_TYPE_ID = 5;

      return this.billingExportSettings.exportTypeId === MEDIC_CLIPBOARD_SFTP_TYPE_ID;
    },

    /**
     * Check export type id is the id of medic clipboard sftp type.
     */
    unfilteredIsMedicClipboardSFTP() {
      const MEDIC_CLIPBOARD_SFTP_TYPE_ID = 5;

      return this.unfilteredExportSettings
          && this.unfilteredExportSettings.exportTypeId === MEDIC_CLIPBOARD_SFTP_TYPE_ID;
    },
    /**
     * Get string with last modified info about API key.
     */
    apiKeyLastModifiedInfo: {
      get() {
        if (this.securitySettingsAPIKey == null) {
          return '';
        }
        const { changedBy, apiKeyChangedAt } = this.securitySettingsAPIKey;
        const dateFrmatOptions = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true,
        };
        const formattedDate = Intl.DateTimeFormat(undefined, dateFrmatOptions)
          .format(new Date(apiKeyChangedAt));
        // Lastly changed by Administrator at 05/28/19 8:57 AM
        return `Lastly changed by ${changedBy.firstName} ${changedBy.lastname} at ${formattedDate}`;
      },
    },
    /**
     * All notification emails joined with comma into single string.
     */
    billingExportNotificationEmails: {
      get() {
        if (this.billingExportSettings.notificationEmails == null) {
          return '';
        }
        return this.billingExportSettings.notificationEmails.join(', ');
      },
    },
    billingExportNotificationEmailsSecondary: {
      get() {
        if (this.billingExportSecondarySettings.notificationEmails == null) {
          return '';
        }
        return this.billingExportSecondarySettings.notificationEmails.join(', ');
      },
    },
  },
  methods: {
    ...mapActions('profileSettings', {
      deleteAccessRestrictionById: DELETE_ACCESS_RESTRICTION,
      refreshAPIKey: UPDATE_API_KEY,
      getAccessRestrictions: GET_ACCESS_RESTRICTIONS,
    }),
    /**
     * Delete access restriction item and update list.
     * @param net
     * @returns {Promise<void>}
     */
    async deleteAcceessRestriction(net) {
      await this.deleteAccessRestrictionById(net.netId);
      this.getAccessRestrictions();
    },

    /**
     * Show edit Access Restriction IP dialog.
     *
     * @param item Access restriction object
     */
    showEditAccessRestriction(item) {
      this.$emit('showEditAccessRestriction', item);
    },

    /**
     * Show confirmation for deleting access restriction row.
     * @param item Access restriction object
     */
    showDeleteRowPopup(item) {
      this.$refs.confirmationPopup.showConfirm(
        this.$t('profileSettings.deleteAccessRestrictionTitle'),
        `Do you really want to delete access restriction ${item.name} with ip ${item.net}?`,
        () => {
          this.deleteAcceessRestriction(item);
        },
      );
    },
  },
};
</script>

<style lang="scss" module>
.textField {
  max-width: 120px;
}

.apiKey {
  max-width: 456px;
}

.card {
  padding: 14px;
  height: 100%;
}

.apiKeyButton {
  margin-left: 10px;
}

.exportSetupButton {
  margin-top: 10px;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.row {
  display: flex;
  align-items: center;
}

.redLabel {
  color: #cc2424;
}
</style>
