import _ from 'lodash';

const validation = {
  validateFormData(formData, structure) {
    let errorMessages = {};
    _.forEach(structure, (fieldProperties, field) => {
      if (fieldProperties.type === 'object') {
        if (fieldProperties.isArray) {
          const objectErrorMessages = this.validateArrayField(field, fieldProperties, formData);
          errorMessages = { ...errorMessages, ...objectErrorMessages };
        } else {
          const objectErrorMessages = this.validateObjectField(field, fieldProperties, formData);
          errorMessages = { ...errorMessages, ...objectErrorMessages };
        }
      } else if (
        fieldProperties.required
        && (!formData || formData[field] === null || formData[field] === undefined)
      ) {
        errorMessages[field] = ['The field is required'];
      }
    });
    return errorMessages;
  },

  validateArrayField(fieldName, fieldProperties, formData) {
    const errorMessages = {};
    if (
      fieldProperties.required
      && (!formData || !formData[fieldName] || !formData[fieldName].length)
    ) {
      errorMessages[fieldName] = ['The field is required'];
    } else if (formData && formData[fieldName]) {
      errorMessages[fieldName] = [];
      formData[fieldName].forEach((formDataItem, index) => {
        const messages = this.validateFormData(
          formDataItem,
          fieldProperties.structure,
        );
        if (Object.keys(messages).length) {
          errorMessages[fieldName][index] = messages;
        }
      });
    }
    return errorMessages;
  },

  validateObjectField(fieldName, fieldProperties, formData) {
    const errorMessages = {};
    if (fieldProperties.required) {
      const messages = this.validateFormData(
        formData ? formData[fieldName] || {} : {},
        fieldProperties.structure,
      );
      const countMessages = Object.keys(messages).length;
      if (!countMessages && (!formData || !formData[fieldName])) {
        errorMessages[fieldName] = ['The field is required'];
      } else if (countMessages) {
        errorMessages[fieldName] = messages;
      }
    } else if (formData && formData[fieldName]) {
      errorMessages[fieldName] = this.validateFormData(
        formData ? formData[fieldName] || {} : {},
        fieldProperties.structure,
      );
    }
    return errorMessages;
  },
};

export default validation;
