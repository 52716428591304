<template>
  <v-select
      v-model="innerValue"
      :label="label"
      :items="itemsPrepared"
      :multiple="multiple"
      :chips="chips"
      :deletable-chips="deletableChips"
      :error-messages="errorMessages"
      :readonly="readonly"
      :disabled="disabled"
      :outlined="outlined"
      :clearable="clearable"
  />
</template>

<script>
export default {
  name: 'SelectField',
  props: {
    value: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    deletableChips: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: [String, Array],
      default: () => [],
    },
  },
  emits: ['input', 'blur'],
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
    itemsPrepared() {
      return this.prepareItems();
    },
  },
  methods: {
    prepareItems() {
      const items = [];
      this.items.forEach(value => {
        items.push({
          text: this.prepareName(value),
          value,
        });
      });
      return items;
    },

    prepareName(name) {
      return name.toLowerCase()
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    },
  },
};
</script>
