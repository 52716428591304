/* eslint func-names:0 */
import _ from 'lodash';

export default {
  props: {
    value: {
      type: [Object, Array],
      required: false,
    },
    emptyObjectsToNull: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      formData: null,
    };
  },
  watch: {
    formData: {
      handler(value) {
        const preparedValue = this.prepareFormData(value);
        this.$emit('input', preparedValue);
      },
      deep: true,
    },
  },
  methods: {
    prepareFormData(formData) {
      if (formData === null) {
        return null;
      }
      if (Array.isArray(formData)) {
        return formData.length ? formData : null;
      }
      if (!this.emptyObjectsToNull) {
        return formData;
      }
      let emptyObject = true;
      _.forEach(formData, (value) => {
        if (Array.isArray(value)) {
          if (value.length) {
            emptyObject = false;
          }
        } else if (value || (typeof value === 'boolean')) {
          emptyObject = false;
        }
      });
      return !emptyObject ? formData : null;
    },
  },
};
