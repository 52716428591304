<template>
  <v-card
      flat
      outlined
      :class="['object-form-' + (nestingNumber > 3 ? '3' : nestingNumber), 'mb-6']"
  >
    <v-toolbar
        v-if="showToolbar"
        flat
        short
    >
      <v-toolbar-title>
        {{ label }}
        <span v-if="multiple">
          ({{ formData && formData.length ? formData.length : 0 }})
        </span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn
        v-if="multiple"
        text
        @click="addItem"
      >+ Add</v-btn>
    </v-toolbar>
    <v-divider v-if="showToolbar"/>
    <v-card-text>
      <simple-form
          v-if="initialized"
          ref="simpleForm"
          v-model="formData"
          :structure="structure"
          :field-properties="fieldProperties"
          :multiple="multiple"
          :nesting-number="nestingNumber"
          :is-dialog="isDialog"
          :dialog-type="dialogType"
          :dialog-title="dialogTitle"
          :empty-objects-to-null="!multiple"
          :error-messages="errorMessages"
      />
    </v-card-text>
  </v-card>
</template>
<script>

import _ from 'lodash';
import formsMixin from './formsMixin';

export default {
  name: 'WrappedForm',

  mixins: [formsMixin],

  components: {
    'simple-form': () => import('@/components/Neris/SimpleForm'),
  },

  props: {
    structure: {
      type: Object,
      required: true,
    },
    fieldProperties: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    nestingNumber: {
      type: Number,
      default: 0,
    },
    showToolbar: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: [Array, Object],
      required: true,
    },
  },
  data() {
    return {
      initialized: false,
    };
  },
  computed: {
    isDialog() {
      return this.fieldProperties.isDialog;
    },
    dialogType() {
      return this.isDialog && this.fieldProperties.class
        ? this.fieldProperties.class
        : null;
    },
    dialogTitle() {
      return this.isDialog
        ? this.fieldProperties.dialogTitle || this.label
        : null;
    },
  },
  mounted() {
    const blankValue = this.multiple ? [] : {};
    this.formData = this.value ? _.cloneDeep(this.value) : blankValue;
    this.initialized = true;
  },
  methods: {
    addItem() {
      this.$refs.simpleForm.addItem();
    },
  },
};
</script>
<style>
.neris-form {
  .object-form-0 {}

  .object-form-1 {
    border: thin solid #9e9e9e !important;
    .v-toolbar {
      background-color: #f6f6f6 !important;
    }
    .v-card__text {
      background-color: #fbfbfb;
    }
  }

  .object-form-2 {
    border: thin solid #9e9e9e !important;
    .v-toolbar {
      background-color: #e9e9e9 !important;
    }
    .v-card__text {
      background-color: #f2f2f2;
    }
  }

  .object-form-3 {
    border: thin solid #9e9e9e !important;
    .v-toolbar {
      background-color: #cfcfcf !important;
    }
    .v-card__text {
      background-color: #eaeaea;
    }
  }
}

</style>
